// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-box {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    display: block;

}


.color-container {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    cursor: pointer;
    transition: all .2s;
}

.color-container:hover {
    border: 2px solid rgb(83, 83, 246);
    padding: 4px;
}

.color-container.active {
    border: 2px solid rgb(83, 83, 246);
    padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Setting/settings.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,cAAc;;AAElB;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,YAAY;AAChB","sourcesContent":[".color-box {\n    width: 100%;\n    height: 100%;\n    border-radius: 30px;\n    display: block;\n\n}\n\n\n.color-container {\n    width: 40px;\n    height: 40px;\n    border-radius: 40px;\n    cursor: pointer;\n    transition: all .2s;\n}\n\n.color-container:hover {\n    border: 2px solid rgb(83, 83, 246);\n    padding: 4px;\n}\n\n.color-container.active {\n    border: 2px solid rgb(83, 83, 246);\n    padding: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
