// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
}

.date-range-picker {
    margin-right: 20px;
    /* Adjust as needed */
}

.switch-container {
    z-index: 1000;
    /* High z-index to stay above the blur */
}

.dashboard-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.private-mode {
    filter: blur(8px);
    pointer-events: none;
}

.private-mode-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    z-index: 999;
    /* Still above the blur but below the switch */
}

@media screen and (max-width: 600px) {
    .switch-container {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 10px auto 5px 20px;
        width: 28%;
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Dashboard.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,8CAA8C;AAClD;;AAEA;IACI;QACI,kBAAkB;QAClB,WAAW;QACX,WAAW;QACX,0BAA0B;QAC1B,UAAU;QACV,aAAa;IACjB;AACJ","sourcesContent":["\n.top-bar {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding: 10px 20px;\n}\n\n.date-range-picker {\n    margin-right: 20px;\n    /* Adjust as needed */\n}\n\n.switch-container {\n    z-index: 1000;\n    /* High z-index to stay above the blur */\n}\n\n.dashboard-content {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.private-mode {\n    filter: blur(8px);\n    pointer-events: none;\n}\n\n.private-mode-text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 32px;\n    font-weight: bold;\n    color: rgb(0, 0, 0);\n    z-index: 999;\n    /* Still above the blur but below the switch */\n}\n\n@media screen and (max-width: 600px) {\n    .switch-container {\n        visibility: hidden;\n        clear: both;\n        float: left;\n        margin: 10px auto 5px 20px;\n        width: 28%;\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
